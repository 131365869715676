import React, {useEffect, useState} from 'react';
import Source from './source';

function Sources() {

    const [sources, setSources] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/sources`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }).then(
            sources => {
                setSources(sources);
                setLoading(false);
            }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            {sources.map((source, index) => (
                <div key={index}>
                    <Source data={source}></Source>
                </div>
            ))}
        </>
    )
}

export default Sources;