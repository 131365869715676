import React, {useEffect, useState} from "react";
import TextBoxEntity from "./textBoxEntity";
import AddTextBox from "./addTextBox";

function TextBoxEntityList({endpoint, afterModHandle}) {

    const [prompts, setPrompts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(endpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }).then(
            prompts => {
                setPrompts(prompts);
                setLoading(false);
            }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const reload = async () => {
        console.log('reload triggered')

        setPrompts(await fetch(endpoint, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }));
    }

    const addHandle = async () => {
        const defaultNew = ''
        await fetch(
            `${endpoint}?value=${defaultNew}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        )
        await reload()
    }

    return (
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px', flexWrap: 'wrap'}}>
            {
                prompts.map((template, index) => (
                    <TextBoxEntity data={template} key={template['_id']} reload_func={reload} endpoint={endpoint}
                                   afterModHandle={afterModHandle}></TextBoxEntity>
                ))
            }

            <AddTextBox addHandle={addHandle}></AddTextBox>

        </div>
    )
}

export default TextBoxEntityList