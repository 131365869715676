import React, {useState} from 'react';
import ProgressBar from "./progressBar";
import ProgressCounter from "./progressCounter";
import ProgressMessage from "./progressMessage";

function DynamicProgress({progress}) {

    let prog

    switch (progress['type']) {
        case 'percent':
            prog = <ProgressBar value={progress['value']} total={progress['total']} />
            break
        case 'counter':
            prog = <ProgressCounter value={progress['value']} />
            break
        case 'message':
            prog = <ProgressMessage message={progress['message']}></ProgressMessage>
            break
        default:
            break
    }

    return (
        <div>
            <label>{progress?.label ?? ''}</label>
            {
                prog
            }
        </div>
    )

}

export default DynamicProgress;