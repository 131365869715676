import React, {useEffect, useRef, useState} from "react";

function TruncatedDiv({text}) {

    const [truncatedText, setTruncatedText] = useState(text)
    const containerRef = useRef(null)

    useEffect(() => {
        function truncateText() {
            const element = containerRef.current;
            const maxHeight = element.getBoundingClientRect().height;

            // Set the full text to measure if truncation is needed
            element.innerText = text;
            if (element.scrollHeight <= maxHeight) {
                setTruncatedText(text); // No truncation needed
                return;
            }

            // Define the starting and ending parts of the text
            let startText = text.slice(0, Math.floor(text.length / 2));  // Adjust for desired length at the beginning
            let endText = text.slice(Math.floor(text.length / 2));      // Adjust for desired length at the end

            // Start with an ellipsis in the middle
            let newText = `${startText}\n...\n${endText}`;
            element.innerText = newText;

            // Further shorten text if it's still overflowing
            while (element.scrollHeight > maxHeight && newText.length > 4) {
                startText = startText.slice(0, -1); // Remove one character from the beginning
                endText = endText.slice(1);         // Remove one character from the end
                newText = `${startText}\n...\n${endText}`;
                element.innerText = newText;
            }

            setTruncatedText(newText);
        }

        truncateText(); // Run truncation initially

        window.addEventListener('resize', truncateText); // Re-truncate on resize
        return () => window.removeEventListener('resize', truncateText);
    }, [text]);

    return (
        <div ref={containerRef}
            style={{whiteSpace: 'pre-wrap', width:'100%', height:'100%', padding:'10px', boxSizing:"border-box"}}>
            {truncatedText}
        </div>
    )

}

export default TruncatedDiv