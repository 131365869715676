import React, {useEffect, useRef, useState} from "react";

function SitemapIgnore() {
    const [ignores, setIgnores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const ignoreValues = useRef(ignores.join('\n'))

    useEffect(() => {
        fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/sitemap-ignore`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }).then(
            ignores => {
                setIgnores(ignores);
                setLoading(false);
            }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [])


    const updateSiteIgnore = async (event) => {
        const ignoreList = ignoreValues.current.split('\n')
        await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/sitemap-ignore`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ignores: ignoreList})
            })
    }


    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div style={{display: 'flex'}}>
            <textarea name="sitemap-ignore" id="" cols="30" rows="10" defaultValue={ignores.join('\n')} onChange={e => {
                ignoreValues.current = e.target.value
            }}></textarea>
            <button onClick={updateSiteIgnore}>SAVE</button>
        </div>

    )
}

export default SitemapIgnore