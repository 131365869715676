import React, {useEffect, useState} from 'react';
import Preference from './preference';

function Preferences() {

    const [preferences, setPreferences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/preferences`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }).then(
            preferences => {
                setPreferences(preferences);
                setLoading(false);
            }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            {preferences.map((pref, index) => (
                <div key={index}>
                    <Preference data={pref}></Preference>
                </div>
            ))}
        </>
    )
}

export default Preferences;