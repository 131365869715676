import './App.css';
import React, {useRef, useState, useEffect} from "react";

import AdminSection from "./components/admin-section";
import Sources from "./components/sources";
import Preferences from "./components/preferences";
import SitemapIgnore from "./components/sitemapIgnore";
import ScheduleSetter from "./components/scheduleSetter";
import RunMonitor from "./components/runMonitor";
import TextBoxEntityList from "./components/textBoxEntityList";
import Login from "./components/login";
import TemplateVars from "./components/templateVars";

function App() {

    const [runLog, setRunLog] = useState({});
    const [runStatus, setRunStatus] = useState({});
    const socket = useRef(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem("token");

            if (!token) {
                setIsAuthenticated(false);
                console.log('NO TOKEN')
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/auth/validate`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`, // Include the JWT token
                    },
                });

                if (response.ok) {
                    // Token is valid
                    setIsAuthenticated(true);
                } else {
                    // Token is invalid
                    localStorage.removeItem("token");
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error("Error validating token:", error);
                setIsAuthenticated(false);
            }
        };

        validateToken();
    }, []);


    useEffect(() => {
        if (isAuthenticated) {
            socket.current = new WebSocket(`${process.env.REACT_APP_UPDATE_WEBSOCKET}/api/admin/run/progress`)
            socket.current.onopen = () => {
                console.log('Connected to websocket')
            }
            socket.current.onmessage = onMessage
        }

    }, [isAuthenticated]);

    const onMessage = (event) => {
        const data = JSON.parse(event.data)
        if (data['type'] === 'run_status') {
            setRunStatus(data['content'])
        } else if (data['type'] === 'run_progress') {
            setRunLog(prevState => Object.assign({}, prevState, {[data['key']]: data['content']}))
        }
    }


    const btnRunBtnHandler = async () => {
        await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/run/`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
    }

    //console.log(runLog)


    return (

        <div className="App">

            {isAuthenticated ? (
                <div style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '50px'}}>
                    <h1>Chatbot Admin</h1>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                        <AdminSection sectionTitle={'Update schedule'}>
                            <ScheduleSetter></ScheduleSetter>
                        </AdminSection>
                        <AdminSection sectionTitle={'Update monitor'}>
                            <button className="btn btn-primary" onClick={btnRunBtnHandler}>RUN NOW</button>
                            <RunMonitor status={runStatus} log={runLog}></RunMonitor>
                        </AdminSection>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                        <AdminSection sectionTitle={'Source manager'}>
                            <Sources></Sources>
                        </AdminSection>

                        <AdminSection sectionTitle={'Sitemap Ignore'}>
                            <SitemapIgnore></SitemapIgnore>
                        </AdminSection>
                    </div>

                    <AdminSection sectionTitle={'Preferences'}>
                        <Preferences></Preferences>
                    </AdminSection>

                    <AdminSection sectionTitle={'Template variables'}>
                        <TemplateVars></TemplateVars>
                    </AdminSection>

                    <AdminSection sectionTitle={'Prompt templates'}>
                        <TextBoxEntityList endpoint={`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/templates`}
                                           afterModHandle={async () => {
                                           }}></TextBoxEntityList>
                    </AdminSection>

                    <AdminSection sectionTitle={'Test documents'}>
                        <TextBoxEntityList endpoint={`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/test-docs`}
                                           afterModHandle={async () => {
                                           }}></TextBoxEntityList>
                    </AdminSection>
                </div>
            ) : (
                <Login onLogin={() => setIsAuthenticated(true)}></Login>
            )}


        </div>
    );
}

export default App;
