import React, {useState} from "react";

function Source({data}) {

    const checkHandler = async (event, target) => {


        console.log(`${data['source']} - ${target} - ${event.target.checked}`);

        await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/sources?source=${data["source"]}&target=${target}&value=${event.target.checked}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
        /*if (target === 'enabled') {
            await fetch(`${process.env.REACT_APP_CHAT_ENDPOINT}/api/chat/reload`)
        }*/
    }

    return (
        <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent:'space-between'}}>
            <h5 style={{}}>{data['title']}</h5>
            <div style={{}}>
                <label>
                    Update
                    <input type="checkbox" defaultChecked={data['update']}
                           onChange={event => checkHandler(event, 'update')} style={{margin: "10px"}}/>
                </label>

                <label>
                    Embed
                    <input type="checkbox" defaultChecked={data['embed']}
                           onChange={event => checkHandler(event, 'embed')} style={{margin: "10px"}}/>
                </label>

                <label>
                    Enable
                    <input type="checkbox" defaultChecked={data['enabled']}
                           onChange={event => checkHandler(event, 'enabled')} style={{margin: "10px"}}/>
                </label>
            </div>

        </div>
    )
}

export default Source;