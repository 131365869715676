import React, {useEffect, useRef, useState} from "react";
import TruncatedDiv from "./truncatedDiv";
import './styles/textBoxEntity.css'

function TextBoxEntity({data, reload_func, endpoint, afterModHandle}) {
    //console.log(data);

    const value = useRef(data['value'])
    const enabled = useRef(data['enabled'])
    const [opened, setOpened] = useState(false)
    const [hovered, setHovered] = useState(false)


    const deleteEntity = async () => {
        await fetch(
            `${endpoint}?_id=${data['_id']}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        )
        await reload_func()
        await afterModHandle()
    }

    const updateEntity = async (event) => {
        await fetch(
            `${endpoint}?_id=${data['_id']}&value=${encodeURIComponent(value.current)}&enabled=${enabled.current}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        )
        await reload_func()
        await afterModHandle()
    }

    let textOverlay = ''


    if (opened) {
        textOverlay = (
            <div className={'text-box-entity-edit-overlay'}
                 onClick={() => setOpened(false)}>
                <textarea className={'text-box-entity-edit-box'} name="prompts" id="prompts" cols="60" rows="30"
                          defaultValue={data['value']}
                          placeholder={'This is a pretty useless prompt so far...'}
                          onChange={e => {
                              value.current = e.target.value
                          }}
                          onClick={(e) => {
                              e.stopPropagation()
                          }}>
                </textarea>
                <div style={{display: 'flex', gap: '10px'}}>
                    <button>Cancel</button>
                    <button onClick={updateEntity}>Save</button>
                </div>

            </div>
        )
    }

    let overlay = ''

    if (hovered) {
        overlay =
            <div className={'text-box-entity-overlay'}>
                <label style={{color: '#cccccc', fontSize: '16pt'}} onClick={async (e) => {
                    e.stopPropagation()
                    setOpened(true)
                }}>Edit</label>
                <label style={{color: '#dd0000', fontWeight: 'bolder', fontSize: '16pt'}}
                       onClick={async (e) => {
                           e.stopPropagation()
                           await deleteEntity()
                       }}>DELETE</label>

            </div>

    }

    return (
        <>
            <div className={'text-box-entity-wrapper'}>
                <div className={'text-box-entity-content'} style={{
                    outlineStyle: enabled.current ? 'solid' : 'none',
                }}
                     onClick={async (e) => {
                         e.stopPropagation()
                         enabled.current = !enabled.current
                         await updateEntity(e)
                     }}
                     onMouseOver={() => setHovered(true)}
                     onMouseOut={() => setHovered(false)}>
                    <TruncatedDiv text={data['value']}></TruncatedDiv>
                    {overlay}
                </div>
            </div>
            {textOverlay}
        </>

    )
}

export default TextBoxEntity