import React from 'react';
import './styles/admin-section.css'

function AdminSection({children, sectionTitle}) {
    return (
        <div className="admin-section">
            <h2>{sectionTitle}</h2>
            {children}
        </div>
    )
}

export default AdminSection;