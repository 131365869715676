import {useRef} from "react";

function TemplateVar({data, reload_func}) {

    const varKey = useRef(data['var'])
    const varValue = useRef(data['value'])

    const updateTempVar = async (event) => {
        await fetch(
            `${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/template-vars?_id=${data['_id']}&temp_var_key=${encodeURIComponent(varKey.current)}&temp_var_value=${encodeURIComponent(varValue.current)}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        )
        await reload_func()
    }

    const deleteTempVar = async () => {
        await fetch(
            `${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/template-vars?_id=${data['_id']}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        )
        await reload_func()
    }

    const handleChange = async (toSet, val) => {
        if (toSet==='key') {
            varKey.current = val
        }
        else if (toSet==='value') {
            varValue.current = val
        }
        await updateTempVar()
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            gap: '10px',
            alignItems: 'center',
        }}>
            <input type={'text'} contentEditable={true} defaultValue={data['var']} onChange={async (e) => await handleChange('key', e.target.value)} style={{
                flex: 1,
                backgroundColor: '#222222',
                borderRadius: '5px',
                padding: '10px',
                outline: 'solid #aaaaaa 1px',
                border: 'none',
                fontWeight: 'bold',
                color: 'white',
            }}></input>
            <input type={'text'} contentEditable={true} defaultValue={data['value']} onChange={async (e) => await handleChange('value', e.target.value)} style={{
                flex: 1,
                backgroundColor: '#222222',
                borderRadius: '5px',
                padding: '10px',
                outline: 'solid #aaaaaa 1px',
                border: 'none',
                fontStyle: 'italic',
                color: 'white',
            }}></input>
            <div onClick={deleteTempVar} style={{
                width: '30px',
                flexGrow: 0,
                flexShrink: 0,
                height: '100%',
                display: "flex",
                justifyContent: 'center',
                cursor: 'pointer'
            }}>
                <div>X</div>
            </div>
        </div>
    )
}

export default TemplateVar