import React from 'react';
import './styles/progressBar.css'

function ProgressBar({value, total}) {

    return (
        <div>
            <div className={'progress-bar'}>
                <div className={'progress-bar-fill'} style={{width: `${value / total * 100}%`}}></div>
            </div>
            {value} / {total}
        </div>


    )
}

export default ProgressBar