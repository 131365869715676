import React, {useEffect, useState} from 'react';
import Preference from './preference';
import TemplateVar from "./templateVar";

function TemplateVars(props) {

    const [templateVars, setTemplateVars] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const reload = async () => {
        console.log('vars reload triggered')

        setTemplateVars(await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/template-vars`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }));
    }

    const addHandle = async () => {
        const defaultKey = ''
        const defaultValue = ''
        await fetch(
            `${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/template-vars?temp_var_key=${encodeURIComponent(defaultKey)}&temp_var_value=${encodeURIComponent(defaultValue)}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            }
        )
        await reload()
    }

    useEffect(() => {
        fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/template-vars`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }).then(
            templateVars => {
                setTemplateVars(templateVars);
                setLoading(false);
            }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
            {templateVars.map((tempVar, index) => (
                <div key={index}>
                    <TemplateVar data={tempVar} reload_func={reload}></TemplateVar>
                </div>
            ))}
            <div onClick={addHandle} style={{
                width: '100%',
                backgroundColor: '#777777',
                borderRadius:'5px',
                fontWeight: 'bolder',
                color: '#444444',
                height: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
            }}>+</div>
        </div>
    )
}

export default TemplateVars;