import React from "react";
import './styles/addTextBox.css'

function AddTextBox({addHandle}) {
    return (
        <div className={'add-text-box'} onClick={addHandle}>
            +
        </div>
    )
}

export default AddTextBox;