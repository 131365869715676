import React, {useEffect, useState} from "react";

function ScheduleSetter() {
    const [interval, setInterval] = useState({
        days: "",
        hours: "",
        minutes: ""
    });

    const [date, setDate] = useState('');
    const [time, setTime] = useState('');

    const [enabled, setEnabled] = useState(null)

    //const [preferences, setPreferences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/schedule`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Error occurred');
            }
            return response.json();
        }).then(
            schedule => {
                setDate(schedule['start_dt'].split('T')[0]);
                setTime(schedule['start_dt'].split('T')[1])
                setInterval(schedule['interval'])
                setEnabled(schedule['enabled'])
                setLoading(false);
            }
        ).catch(error => {
            setError(error);
            setLoading(false);
        })
    }, [])


    const handleChange = (e) => {
        const {name, value} = e.target;
        setInterval((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };

    const handleTimeChange = (e) => {
        setTime(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const [hours, minutes] = time.split(':').map(Number)
        const newDate = new Date(date);
        newDate.setHours(hours, minutes, 0);

        const utcDate = new Date(
            Date.UTC(
                newDate.getFullYear(),
                newDate.getMonth(),
                newDate.getDate(),
                newDate.getHours(),
                newDate.getMinutes()
            ))

        console.log(
            utcDate
        )


        const convertedInterval = Object.fromEntries(
            Object.entries(interval).map(([key, value]) => [key, parseInt(value, 10)])
        );

        console.log(
            convertedInterval
        )


        await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/schedule`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
                interval: convertedInterval,
                start_dt: utcDate,
            })
        })

    };

    const handleEnable = async (e) => {
        await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/schedule/enable?value=${e.target.checked}`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            })
        setEnabled(e.target.checked)
    }

    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }


    return (
        <form onSubmit={handleSubmit} style={{width: "fit-content"}}>
            <div style={{display: "flex", flexDirection: "column", gap: '10px'}}>
                <div style={{display: "flex", gap: '10px', alignItems: 'center', marginBottom: '10px'}}>
                    <label>Enable scheduled updates</label>
                    <input type={"checkbox"} onChange={handleEnable} defaultChecked={enabled}></input>
                </div>
                <label>Interval (days – hours – minutes)</label>
                <div style={{display: "flex", gap: '10px'}}>
                    <input
                        type="number"
                        name="days"
                        min="0"
                        max="99"
                        value={interval.days}
                        onChange={handleChange}
                    />
                    <input
                        type="number"
                        name="hours"
                        min="0"
                        max="23"
                        value={interval.hours}
                        onChange={handleChange}
                    />
                    <input
                        type="number"
                        name="minutes"
                        min="0"
                        max="59"
                        value={interval.minutes}
                        onChange={handleChange}
                    />
                </div>
                <label>Start datetime</label>
                <div style={{display: "flex", gap: '10px'}}>
                    <input
                        type="date"
                        value={date}
                        onChange={handleDateChange}
                    />
                    <input
                        type="time"
                        value={time}
                        onChange={handleTimeChange}
                    />
                </div>
                <button type="submit" style={{width: '100%'}}>Schedule</button>
            </div>
        </form>
    );
}

export default ScheduleSetter;