import React, {useState} from "react";

function Preference({data}) {

    const prefHandler = async (event) => {


        console.log(`${data['key']} - ${event.target.value}`);

        await fetch(`${process.env.REACT_APP_UPDATE_ENDPOINT}/api/admin/preferences?key=${data["key"]}&value=${event.target.value}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }
        })
        //await fetch(`${process.env.REACT_APP_CHAT_ENDPOINT}/api/chat/reload`)
    }

    return (
        <div style={{display: "flex", flexDirection: "row", width: "100%", alignItems: "center", }}>
            <h4 style={{width:"50%"}}>{data['title']}</h4>
            <input type={"number"} min={data['min']} max={data['max']} defaultValue={data['value']} onChange={prefHandler}/>
        </div>
    )
}

export default Preference;