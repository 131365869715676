import React from "react";
import DynamicProgress from "./dynamicProgress";
import RunStatus from "./runStatus";

function RunMonitor({status, log}) {

    return (
        <>
            <RunStatus status={status}></RunStatus>
            {
                Object.keys(log).map(
                    key => {
                        return <DynamicProgress key={key} progress={log[key]}/>
                    }
                )
            }
        </>
    )

}

export default RunMonitor